<template>
  <v-row>
      <v-col cols="12">
          <base-card>
              <v-card-title>
                  <div class="d-flex justify-space-between flex-wrap">
                      <v-btn class="ma-2" dark color="danger">
                          <v-icon>mdi-plus</v-icon>
                          Add Transaction
                      </v-btn>
                  </div>
              </v-card-title>
              <v-card-title>
                  Transactions
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>

                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                  ></v-text-field>
              </v-card-title>
              <v-data-table
                  :search="search"
                  :headers="headers"
                  :items="items"
                  item-key="name"
                  show-select
                  class="elevation-1 table-one"
                  multi-sort
              >
                  <template v-slot:item.name="{item}">
                      <div class="d-flex align-center">
                          <v-avatar class="mr-2" size="26" dark>
                              <img :src="item.img" alt="" />
                          </v-avatar>
                          <p class="ma-0 font-weight-medium">
                              {{ item.name }}
                          </p>
                      </div>
                  </template>
                  <template v-slot:item.action="{item}">
                      <div class="d-flex">
                          <v-tooltip top>
                              <template v-slot:activator="{on, attrs}">
                                  <v-btn
                                      color="success"
                                      dark
                                      v-bind="attrs"
                                      v-on="on"
                                      icon
                                  >
                                      <v-icon>mdi-pencil-box-outline</v-icon>
                                  </v-btn>
                              </template>
                              <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip top>
                              <template v-slot:activator="{on, attrs}">
                                  <v-btn
                                      color="danger"
                                      dark
                                      v-bind="attrs"
                                      v-on="on"
                                      icon
                                  >
                                      <v-icon>mdi-trash-can-outline</v-icon>
                                  </v-btn>
                              </template>
                              <span>Delete</span>
                          </v-tooltip>
                      </div>
                  </template>
              </v-data-table>
          </base-card>
      </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Transactions'
  },
  data() {
      return {
          search: '',
          headers: [
              {
                  text: 'Create Date',
                  align: 'start',
                  sortable: false,
                  value: 'createDate'
              },
              {text: 'Investment', value: 'investment'},
              {text: 'Type', value: 'type'},
              {text: 'Currency Quantity', value: 'currencyQuantity'},
              {text: 'Currency Value', value: 'currencyValue'},
              {text: 'Fee', value: 'fee'},
              {text: 'Notes', value: 'notes'}
          ],
          items: [
              {
                  createDate: '2022-10-01',
                  investment: 'Prosperity Gem Ventures',
                  type: 'Invest',
                  currencyQuantity: '100.00',
                  currencyValue: '1.00',
                  fee: '0.34',
                  notes: ''
              },
              {
                  createDate: '2022-10-05',
                  investment: 'Prosperity Gem Ventures',
                  type: 'Rollover',
                  currencyQuantity: '50.00',
                  currencyValue: '1.00',
                  fee: '0.00',
                  notes: ''
              },
              {
                  createDate: '2022-10-10',
                  investment: 'Prosperity Gem Ventures',
                  type: 'Rollover',
                  currencyQuantity: '50.00',
                  currencyValue: '1.00',
                  fee: '0.00',
                  notes: ''
              }
          ]
      }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .table-one {
  thead.v-data-table-header {
      tr {
          &:hover {
              background-color: #f2f3f8;
          }
          th {
              span {
                  font-size: 16px;
                  color: #304156;
              }
          }
      }
      tr {
          td {
              padding-bottom: 20px;
              padding-top: 20px;
          }
      }
  }
  tbody {
      tr {
          &:hover {
              background-color: #f2f3f8 !important;
          }
      }
  }
}
</style>
